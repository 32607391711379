


























import type { PropType } from 'vue';

export default {
  name: 'PromoViewRewardAlertDialog',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    successfulReward: {
      type: Array as PropType<string[]>,
      default: (): string[] => []
    },
    rejectedReward: {
      type: Array as PropType<string[]>,
      default: (): string[] => []
    }
  },
  computed: {
    isSuccess(): boolean {
      return !this.rejectedReward.length;
    }
  }
};
