
























































































import { mapActions, mapState } from 'vuex';
import {
  addPromoCampaignCandidates,
  getPromoCampaignCandidates,
  getPromoCampaignsParticipants,
  giveReward,
  removeReward,
  removeUserFromCampaign
} from '@/api/PromoCampaign';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import PromoViewRewardAlertDialog from '@/views/PromoView/PromoViewRewardAlertDialog.vue';
import { textOverflowMiddle } from '@/helpers/formatString';
import UserIdInput from '@/components/UserIdInput.vue';

export default {
  name: 'PromoViewAddUsersDialog',
  components: {
    UserIdInput,
    PromoViewRewardAlertDialog
  },
  data: (): any => ({
    showRewardAlert: false,
    successfulReward: [],
    rejectedReward: [],
    saving: false,
    search: null,
    users: [],
    loadUsers: false,
    currentList: [],
    operatorUserId: null,
    inputValue: null,
    hasErrors: null
  }),
  computed: {
    ...mapState('Promo', ['selectedCampaign', 'dialog']),
    isRewards(): boolean {
      return this.dialog.giveReward && !this.dialog.restrictionList;
    },
    subTitle(): string {
      if (this.dialog.restrictionList)
        return 'Add users who can participate in marketing campaigns.';
      if (!this.isRewards)
        return 'Choose users who can participate in the promo to create the campaign user list. Insert chosen user IDs one by one per line.';
      return 'Add users who can participate in a marketing campaigns';
    },
    sendData(): string[] {
      return this.hasErrors ? [] : this.users;
    },
    idsList(): string[] {
      return this.operatorUserId
        ? this.currentList.filter((id) =>
            id.toLowerCase().includes(this.operatorUserId.toLowerCase())
          )
        : this.currentList;
    },
    isDisabledAddBtn(): boolean {
      return !!(
        this.hasErrors ||
        !this.users.length ||
        !this.$role.can.update('promo')
      );
    }
  },
  watch: {
    'dialog.restrictionList'(show: boolean): void {
      if (!show) return;
      this.getList();
    },
    'dialog.giveReward'(show: boolean): void {
      if (!show) return;
      this.getList();
    }
  },
  methods: {
    ...mapActions('Promo', ['showRestrictionDialog', 'showGiveRewardDialog']),
    textOverflowMiddle,
    async getList(): Promise<any> {
      if (!this.selectedCampaign) {
        return Promise.reject(new Error('Unknown campaign'));
      }

      this.clearUsers();

      this.loadUsers = true;
      let data;

      try {
        if (this.isRewards) {
          data = await getPromoCampaignsParticipants(
            { page: 0, size: 50, operatorUserId: this.operatorUserId },
            this.selectedCampaign.id
          );

          this.loadUsers = false;

          data = (data?.content ?? []).map((v) => v.operatorUserId);
        } else {
          data = await getPromoCampaignCandidates(this.selectedCampaign.id);

          if (this.operatorUserId > 3) {
            data = data.filter((v) =>
              v.toLowerCase().includes(this.operatorUserId)
            );
          }
        }

        this.currentList = data;
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.loadUsers = false;
      }

      return data;
    },
    async updateList(): Promise<any> {
      if (this.isRewards) {
        const promises = this.sendData.map((id: string) =>
          giveReward(this.selectedCampaign.id, id)
            .then((): void => {
              this.successfulReward.push(id);
            })
            .catch((): void => {
              this.rejectedReward.push(id);
            })
        );

        return Promise.all(promises)
          .then((): void => {
            this.clearUsers();
            this.getList();
          })
          .finally((): void => {
            if (this.rejectedReward.length) {
              this.showRewardAlert = true;
            } else {
              this.$toast.success(
                `Users has been successfully added to ${this.selectedCampaign.name}.`
              );
            }
          });
      }

      return addPromoCampaignCandidates(this.selectedCampaign.id, [
        ...this.sendData,
        ...this.idsList
      ]).then((): void => {
        this.clearUsers();
        this.getList();

        this.$toast.success(
          `Users Restriction List has been added to campaign ${this.selectedCampaign.name}`
        );
      });
    },
    removeUser(operatorUserId: string): void {
      const handler = this.isRewards ? removeReward : removeUserFromCampaign;

      handler(this.selectedCampaign.id, operatorUserId)
        .then(() => {
          this.$toast.success(
            this.isRewards
              ? `User has been removed from rewards of campaign ${this.selectedCampaign.name}`
              : `User with id: ${operatorUserId} has been removed from campaign ${this.selectedCampaign.name}`
          );
        })
        .catch(errorToastMessage)
        .finally((): void => {
          this.getList();
        });
    },
    closeRewardAlert(): void {
      this.showRewardAlert = false;
      this.successfulReward = [];
      this.rejectedReward = [];
    },
    closeAction(): void {
      this.showRestrictionDialog({ status: false });
      this.showGiveRewardDialog({ status: false });
      this.$emit('close');
    },
    clearUsers(): void {
      this.users = [];
    },
    submit(): void {
      this.saving = true;

      this.updateList()
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.saving = false;
        });
    }
  }
};
