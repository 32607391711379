





































import { mapState, mapActions } from 'vuex';
import StatsCard from '@/components/StatsCard.vue';
import PromoViewParticipantsAll from './PromoViewParticipantsAll.vue';
import PromoViewParticipantsCashback from './PromoViewParticipantsCashback.vue';

export default {
  name: 'PromoViewParticipantsDialog',
  components: {
    StatsCard,
    PromoViewParticipantsAll,
    PromoViewParticipantsCashback
  },
  data(): any {
    return {
      currentTabId: 0,
      tabsList: [
        {
          text: 'All users',
          component: 'promo-view-participants-all'
        },
        {
          text: 'Cashback users',
          component: 'promo-view-participants-cashback'
        }
      ]
    };
  },
  computed: {
    ...mapState('Promo', ['selectedCampaign', 'dialog']),

    isCashbackReward(): boolean {
      return (
        !!this.selectedCampaign?.dailyCashbackReward ||
        !!this.selectedCampaign?.weeklyCashbackReward
      );
    }
  },
  methods: {
    ...mapActions('Promo', ['showParticipantsDialog']),

    closeDialog(): void {
      this.showParticipantsDialog({ status: false });
      this.currentTabId = 0;
    }
  }
};
