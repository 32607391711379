






































































import { mapState, mapActions } from 'vuex';
import {
  getPromoCampaignsParticipants,
  getPromoCampaignsParticipantsReward,
  removeReward
} from '@/api/PromoCampaign';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import type { PromoParticipantData } from '@/api/schema';
import { DataTableHeader } from 'vuetify';
import PromoViewParticipantsDialogMixin from './PromoViewParticipantsDialogMixin';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

export default {
  name: 'PromoViewParticipantsAll',
  components: {
    ConfirmationDialog
  },

  mixins: [PromoViewParticipantsDialogMixin],

  props: {
    activeTab: {
      type: Boolean,
      default: false
    }
  },
  data: (): unknown => ({
    showUserDeleteDialogData: null,
    userDeleteDialogData: {
      header: '',
      okText: 'Yes',
      cancelText: 'No',
      handler: null
    },
    reward: 0
  }),
  computed: {
    ...mapState('Promo', ['selectedCampaign', 'dialog']),
    ...mapState('TokensInfo', ['tokenPrice']),

    headers(): DataTableHeader[] {
      return [
        {
          text: 'Operator User ID',
          value: 'operatorUserId',
          width: 360,
          sortable: false
        },
        {
          text: 'Accrual Method',
          value: 'method',
          sortable: false
        },
        {
          text: 'Date',
          value: 'createdAt'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ];
    },
    isActive(): boolean {
      return this.selectedCampaign?.status === 'ACTIVE';
    },
    isCashbackReward(): boolean {
      return (
        !!this.selectedCampaign?.dailyCashbackReward ||
        !!this.selectedCampaign?.weeklyCashbackReward
      );
    }
  },

  methods: {
    ...mapActions('Promo', ['showParticipantsDialog']),
    closeAction(): void {
      this.showParticipantsDialog({ status: false });
    },

    showDeleteUserDialog(item: PromoParticipantData): void {
      this.showUserDeleteDialogData = true;
      this.userDeleteDialogData.header = `Are you sure to delete user ${item.operatorUserId} from campaign ${this.selectedCampaign.name}?`;
      this.userDeleteDialogData.handler = async () => {
        await removeReward(
          this.selectedCampaign?.id,
          item.operatorUserId
        ).then(this.forceUpdate);
      };
    },
    onUserDeleteDialogClosed(): void {
      this.showUserDeleteDialogData = false;
    },
    onUserDeleted(): void {
      this.showUserDeleteDialogData = false;
      this.userDeleteDialogData.handler?.();
    },

    updateList(): void {
      const { id } = this.selectedCampaign || {};
      if (!id || this.loading) return;

      this.loading = true;

      getPromoCampaignsParticipants(this.searchParams, id)
        .then((response): void => {
          this.usersList = response.content;
          this.total = response.totalElements;
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading = false;
        });

      getPromoCampaignsParticipantsReward(this.filter.dates, id)
        .then((response) => {
          this.reward = response;
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        });
    }
  }
};
