











































































































































import { mapActions, mapGetters, mapState } from 'vuex';
import { ConfirmationDialogData } from '@/api/schema';
import {
  createPromoCampaign,
  deletePromoCampaignById,
  updatePromoCampaign,
  updatePromoCampaignStatus
} from '@/api/PromoCampaign';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import PromoCampaignDialog from '@/views/PromoView/PromoCampaignDialog/PromoCampaignDialog.vue';
import PromoViewAddUsersDialog from '@/views/PromoView/PromoViewAddUsersDialog.vue';
import PromoViewGamesBlacklistDialog from '@/views/PromoView/PromoViewGamesBlacklistDialog.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import throttle from 'lodash/throttle';

import type {
  PromoCampaignData,
  PromoCampaignDataRequest,
  PromoCampaignStatus,
  SelectItem
} from '@/api/schema';
import type { AxiosError } from 'axios';
import type { PropType } from 'vue';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10,
  sortBy: ['id'],
  sortDesc: [true]
};

export default {
  name: 'PromoViewCampaigns',
  components: {
    PromoViewGamesBlacklistDialog,
    ConfirmationDialog,
    PromoViewAddUsersDialog,
    PromoCampaignDialog,
    VExtendedDataPicker
  },
  props: {
    currentTab: {
      type: String as PropType<string | null>,
      default: (): string => null
    }
  },
  data: (): any => ({
    newCampaignStatus: null,
    saveCampainData: {} as PromoCampaignData,
    saveCampainId: null,
    savingCampaign: false,
    savingRestrictionList: false,
    options: { ...defaultOptions },
    status: null,
    dates: {
      from: null,
      to: null
    },
    date: null,
    headers: [
      {
        text: 'ID',
        value: 'id'
      },
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Creation Date',
        value: 'createdAt'
      },
      {
        text: 'User Base',
        value: 'participants'
      },
      {
        text: 'Total Reward',
        value: 'sumFixedAmountReward'
      },
      {
        text: 'Status',
        value: 'status-select',
        sortable: false,
        width: '170'
      },
      {
        text: 'Action',
        value: 'actions',
        sortable: false
      }
    ],
    selectItems: [
      {
        text: 'Active',
        value: 'ACTIVE'
      },
      {
        text: 'Deleted',
        value: 'DELETED'
      },
      {
        text: 'Deactivated',
        value: 'DEACTIVATED'
      },
      {
        text: 'Draft',
        value: 'DRAFT'
      },
      {
        text: 'Expired',
        value: 'EXPIRED'
      }
    ]
  }),
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('Promo', [
      'loading',
      'campaigns',
      'selectedCampaign',
      'dialog',
      'needUpdate'
    ]),
    ...mapGetters('Onboarding', ['operatorId']),
    deleteCampaignDialogData(): ConfirmationDialogData {
      const name = this.selectedCampaign?.name ?? '';
      return {
        header: `Are you confirming the deletion of ${name}?`,
        maxWidth: 560
      };
    },
    updateCampaignDialogData(): ConfirmationDialogData {
      const name = this.selectedCampaign?.name ?? '';
      return {
        header: `Do you want to save changes to ${name}?`,
        maxWidth: 560
      };
    },
    currentPage(): number {
      return this.options.page - 1;
    },
    isCampaignTab(): boolean {
      return this.currentTab === 'campaigns';
    },
    needToUpdateCampaign(): string | boolean {
      if (!this.operatorId) return false;

      return JSON.stringify([
        this.options,
        this.status,
        this.dates,
        this.isCampaignTab,
        this.operatorId,
        this.needUpdate
      ]);
    }
  },
  watch: {
    needToUpdateCampaign: {
      handler: 'handleUpdate',
      immediate: true
    }
  },
  methods: {
    ...mapActions('Promo', [
      'showUserSelectDialog',
      'getPromoCampaigns',
      'showDeleteDialog',
      'showGamesBlacklistDialog',
      'showCampaignFormDialog',
      'showParticipantsDialog',
      'showUpdateConfirmationDialog',
      'showUpdateStatusConfirmationDialog',
      'showCampaignFormDialog',
      'showRestrictionDialog',
      'showGiveRewardDialog'
    ]),
    handleUpdate(val: unknown): void {
      if (!val || !this.operatorId) return;
      this.updateCampaignsList();
    },
    updateCampaignsList: throttle(function (): void {
      const { from, to } = this.dates;
      const searchParams: PromoCampaignDataRequest = {
        page: this.currentPage,
        size: this.options.itemsPerPage,
        status: this.status,
        from,
        to,
        direction: this.options.sortDesc[0] ? 'ASC' : 'DESC',
        sortBy: this.options.sortBy[0]
      };
      this.getPromoCampaigns(searchParams);
    }, 1000),
    getSelectData(campaignData: PromoCampaignData): SelectItem[] {
      const { status } = campaignData;
      let enabledItems = [];

      switch (status) {
        case 'ACTIVE':
          enabledItems = ['DEACTIVATED'];
          break;
        case 'DEACTIVATED':
          enabledItems = ['ACTIVE', 'DRAFT'];
          break;
        case 'DRAFT':
          enabledItems = ['ACTIVE'];
          break;
        case 'DELETED':
          enabledItems = ['DRAFT'];
          break;
        case 'EXPIRED':
          enabledItems = ['DRAFT'];
          break;
        default:
          enabledItems = [];
      }

      return this.selectItems.map((item: SelectItem): SelectItem => {
        const v = item.value;
        return {
          ...item,
          disabled: status === v ? false : !enabledItems.includes(v)
        };
      });
    },
    getChangeStatusMessage(status: string, campainName: string): string {
      switch (status) {
        case 'DEACTIVATED':
          return `Campaign ${campainName} has been deactivated`;
        case 'ACTIVE':
          return `Campaign ${campainName} has been activated`;
        case 'DRAFT':
          return `Campaign ${campainName} has been moved to Draft`;

        default:
          return `Campaign status has been updated`;
      }
    },
    changeStatusHandler(
      status: PromoCampaignStatus,
      data: PromoCampaignData
    ): void {
      if (status === this.newCampaignStatus) {
        return;
      }

      if (!this.newCampaignStatus) this.newCampaignStatus = status;
      if (
        (status === 'DEACTIVATED' || status === 'ACTIVE') &&
        !this.dialog.updateStatus
      ) {
        this.showUpdateStatusConfirmationDialog({ status: true, data });
        return;
      }
      const campaign = data || this.selectedCampaign;
      const { id, name } = campaign;

      updatePromoCampaignStatus({ id, status: this.newCampaignStatus })
        .then((): void => {
          this.updateCampaignsList();
          this.showUpdateStatusConfirmationDialog({ status: false });
          const succesMessage = this.getChangeStatusMessage(
            this.newCampaignStatus,
            name
          );
          this.$toast.success(succesMessage);
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.newCampaignStatus = null;
        });
    },
    getUpdateStatusDialogData(): ConfirmationDialogData {
      return {
        header: `Are you confirming the ${
          this.newCampaignStatus === 'ACTIVE' ? 'activation' : 'deactivation'
        } of ${this.selectedCampaign?.name}?`,
        okText:
          this.newCampaignStatus === 'ACTIVE' ? 'Activate' : 'Deactivate',
        maxWidth: 580
      };
    },
    getTotalReward(c: PromoCampaignData): number {
      const check = (v: any) => !!v || typeof v === 'number';

      if (c.accrualCondition) {
        return c.depositReward || 0;
      }

      if (check(c.dailyCashbackReward) || check(c.weeklyCashbackReward)) {
        return c.dailyCashbackRewardAmount || 0;
      }

      if (check(c.sumFixedAmountReward)) {
        return c.sumFixedAmountReward || 0;
      }

      return 0;
    },
    deleteCampaignHandler(): void {
      deletePromoCampaignById(this.selectedCampaign.id)
        .then((): void => {
          this.updateCampaignsList();
          this.showDeleteDialog({ status: false });
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        });
    },
    closeCampaignUpdateConfirmation(): void {
      this.showUpdateConfirmationDialog({ status: false });
      this.showCampaignFormDialog({
        status: true,
        data: { ...this.saveCampainData, id: this.saveCampainId }
      });
    },
    clearUpdateCampaignData(): void {
      this.saveCampainData = {};
      this.saveCampainId = null;
    },
    saveCampaignAction(data: PromoCampaignData, id: number | null): void {
      if (data) {
        this.saveCampainData = data;
        this.saveCampainId = id;
      }

      if (id !== null && !this.dialog.updateConfirmation) {
        this.showUpdateConfirmationDialog({ status: true });
        this.showCampaignFormDialog({ status: false, data });
        return;
      }

      this.$nextTick(() => {
        this.saveCampaignHandler(this.saveCampainData, this.saveCampainId);
      });
    },
    saveCampaignHandler(data: PromoCampaignData, id: number | null): void {
      const handler = id ? updatePromoCampaign : createPromoCampaign;
      this.savingCampaign = true;
      handler(data, id)
        .then((): void => {
          this.updateCampaignsList();
          if (id) {
            this.$toast.success(`Campaign ${data.name} has been updated!`);
          } else {
            this.$refs.promoCampaignForm?.showCongratulations();
          }
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.savingCampaign = false;
          this.clearUpdateCampaignData();
          this.showUpdateConfirmationDialog({ status: false });
        });
    },
    isActive(data: PromoCampaignData): boolean {
      return data.status === 'ACTIVE';
    }
  }
};
