
























































































import { mapActions, mapState, mapGetters } from 'vuex';
import { AxiosError } from 'axios';
import { DataTableHeader } from 'vuetify';
import {
  SelectItem,
  IMenuFilterItem,
  GameProviderData,
  IPromoGameBlackListItem,
  IPromoGameBlackListParams
} from '@/api/schema';

import { errorToastMessage } from '@/helpers/errorToastMessage';
import {
  includedGamesPromoCampaign,
  excludedGamesPromoCampaign,
  getGamesBlacklistPromoCampaign
} from '@/api/PromoCampaign';

import BaseDialog from '@/components/BaseDialog.vue';
import VMenuFilter from '@/components/VMenuFilter.vue';
import { toTitleCase } from '@/helpers/formatString';

import { pluralize } from '@/helpers/pluralize';

interface ISelectedTable {
  item?: IPromoGameBlackListItem;
  items?: IPromoGameBlackListItem[];
  value: boolean;
}

enum ESearchByOptions {
  GAME_NAME = 'name',
  GAME_CODE = 'code'
}

const defaultOptions = {
  page: 1,
  itemsPerPage: 8
};

export default {
  name: 'PromoViewGamesBlacklistDialog',

  components: {
    BaseDialog,
    VMenuFilter
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data(): any {
    return {
      options: { ...defaultOptions },
      filter: {
        searchBy: ESearchByOptions.GAME_NAME,
        search: '',
        providers: []
      },
      loading: true,
      rows: [],
      selectedGames: [],
      changedItems: {},
      totalElements: null
    };
  },

  computed: {
    ...mapState('Promo', ['selectedCampaign', 'dialog']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('Onboarding', ['providers']),
    ...mapGetters('Onboarding', ['operatorId']),

    providersList(): IMenuFilterItem[] {
      return this.providers.map((provider: GameProviderData) => ({
        text: toTitleCase(provider.name),
        value: provider.name
      }));
    },

    canEdit(): boolean {
      return this.$role.can.update('promo');
    },

    titleDialog(): string {
      if (!this.selectedCampaign) return '';

      const namePromo = this.selectedCampaign.name;

      return `Games Blacklist for "${namePromo}"`;
    },

    tableHeaders(): DataTableHeader[] {
      return [
        {
          text: 'Game Code',
          value: 'code'
        },
        {
          text: 'Game Name',
          value: 'name'
        },
        {
          text: 'Game Provider',
          value: 'provider'
        }
      ];
    },

    searchByOptions(): SelectItem[] {
      return [
        {
          text: 'Game Name',
          value: ESearchByOptions.GAME_NAME
        },
        {
          text: 'Game Code',
          value: ESearchByOptions.GAME_CODE
        }
      ];
    },

    currentSearchBy(): SelectItem {
      return this.searchByOptions.find(
        (el: SelectItem) => el.value === this.filter.searchBy
      );
    },

    searchPlaceholder(): string {
      return `Search by ${this.currentSearchBy.text}`;
    },

    selectedProviders(): string[] {
      return this.filter.providers.map((item) => item.value);
    },

    requestParams(): IPromoGameBlackListParams {
      const { searchBy, search } = this.filter;

      return {
        [searchBy]: search || null,
        providers: this.selectedProviders,
        page: this.options.page - 1,
        size: this.options.itemsPerPage
      };
    },

    excludedIds(): string[] {
      return this.getChangedItemsIds(true);
    },

    includedIds(): string[] {
      return this.getChangedItemsIds();
    },

    disabledButton(): boolean {
      return !(this.excludedIds.length || this.includedIds.length);
    }
  },

  watch: {
    options: 'updateGamesBlacklist',

    value(open: boolean): void {
      if (open) {
        if (!this.providers.length) this.getProviders();

        this.options = { ...defaultOptions };
        this.resetChangeItems();
      }
    }
  },

  methods: {
    ...mapActions('Onboarding', ['getProviders']),
    toTitleCase,

    updateFilter(): void {
      this.updateGamesBlacklist();
      this.resetSelectedGames();
      this.resetChangeItems();
      this.resetPage();
    },

    updateGamesBlacklist(): void {
      this.loading = true;

      getGamesBlacklistPromoCampaign(
        this.selectedCampaign.id,
        this.requestParams
      )
        .then((response) => {
          this.rows = response.content;
          this.totalElements = response.totalElements;
          this.setInitialSelectedItems(response.content);
        })
        .catch((err: AxiosError) => errorToastMessage(err))
        .finally(() => {
          this.loading = false;
        });
    },

    checkChangedItems(item: IPromoGameBlackListItem): boolean {
      const excludedItem = this.excludedIds.includes(item.id);
      const includedItem = this.includedIds.includes(item.id);

      if (excludedItem) return excludedItem;

      if (includedItem) return !includedItem;

      return item.excluded;
    },

    setInitialSelectedItems(items: IPromoGameBlackListItem[]): void {
      this.selectedGames = items.filter(this.checkChangedItems);
    },

    changeSelectedItem({ item, value }: ISelectedTable): void {
      this.setSelectedItem(item, value);
    },

    changeSelectedAllItems({ items, value }: ISelectedTable): void {
      items.forEach((item) => this.setSelectedItem(item, value));
    },

    setSelectedItem(item: IPromoGameBlackListItem, value: boolean): void {
      const initialItem = this.rows.find(
        (row: IPromoGameBlackListItem) => row.id === item.id
      );

      initialItem.excluded !== value
        ? this.$set(this.changedItems, item.id, value)
        : this.$delete(this.changedItems, item.id);
    },

    getChangedItemsIds(excluded: boolean = false): number[] {
      return Object.entries(this.changedItems)
        .filter(([_, value]) => value === excluded)
        .map(([key]) => +key);
    },

    getCountGamesLabel(count: number): string {
      return `${count} ${pluralize(count, ['game', 'games', 'games'])}`;
    },

    resetPage(): void {
      this.options.page = 1;
    },

    resetChangeItems(): void {
      this.changedItems = {};
    },

    resetSelectedGames(): void {
      this.selectedGames = [];
    },

    handleClickSearchButton(): void {
      this.resetPage();
      this.resetSelectedGames();
      this.resetChangeItems();
      this.updateGamesBlacklist();
    },

    handleClickSearchClearButton(): void {
      this.filter.search = null;
      this.resetPage();
      this.resetSelectedGames();
      this.resetChangeItems();
      this.updateGamesBlacklist();
    },

    handleClickCancelButton(): void {
      this.resetChangeItems();
      this.setInitialSelectedItems(this.rows);
      this.resetPage();
      this.updateGamesBlacklist();
    },

    async handleClickApplyButton(): Promise<void> {
      const countExcludedLabel = this.getCountGamesLabel(
        this.excludedIds.length
      );

      const countIncludedGames = this.getCountGamesLabel(
        this.includedIds.length
      );

      if (this.disabledButton) return;

      try {
        if (this.includedIds.length) {
          await includedGamesPromoCampaign(
            this.selectedCampaign.id,
            this.includedIds
          );

          if (!this.excludedIds.length) {
            this.$toast.success(
              `${countIncludedGames} removed from the promo blacklist of the company "${this.selectedCampaign.name}"`
            );
          }
        }

        if (this.excludedIds.length) {
          await excludedGamesPromoCampaign(
            this.selectedCampaign.id,
            this.excludedIds
          );

          if (!this.includedIds.length) {
            this.$toast.success(
              `${countExcludedLabel} added to the promo blacklist of the company "${this.selectedCampaign.name}"`
            );
          }
        }

        if (this.includedIds.length && this.excludedIds.length) {
          this.$toast.success(
            `${countExcludedLabel} added and ${countIncludedGames} removed from the promo blacklist of the company "${this.selectedCampaign.name}"`
          );
        }

        this.resetPage();
        this.resetSelectedGames();
        this.updateGamesBlacklist();
        this.$emit('close');
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
