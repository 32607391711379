






























































import PromoViewParticipantsDialogMixin from './PromoViewParticipantsDialogMixin';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { doDownload } from '@/helpers/download';
import numeralSpaces from '@/filters/numeral-spaces';
import {
  getPromoCampaignsCashbackTotal,
  getPromoCampaignsCashbackUsers,
  getPromoCampaignsCashbackUsersReport
} from '@/api/PromoCampaign';
import { DataTableHeader } from 'vuetify';
import type { AxiosError } from 'axios';
import { DateTime } from 'luxon';

const defaultDates = {
  from: DateTime.now().minus({ month: 1 }).toFormat('yyyy-LL-dd'),
  to: DateTime.now().toFormat('yyyy-LL-dd')
};

export default {
  name: 'PromoViewParticipantsCashback',

  mixins: [PromoViewParticipantsDialogMixin],

  data(): any {
    return {
      defaultDates,
      filter: {
        operatorUserId: null,
        dates: defaultDates
      },
      totalCashback: null,
      isCSVLoading: false
    };
  },

  computed: {
    headers(): DataTableHeader[] {
      return [
        {
          text: 'Operator User ID',
          value: 'operatorUserId',
          width: 360,
          sortable: false
        },
        {
          text: 'Quantity',
          value: 'quantity',
          sortable: false
        },
        {
          text: 'Total Amount',
          value: 'reward',
          sortable: true
        }
      ];
    },

    prepareRewardInfo(): { valueCurrency: string; valueFiat: string | null } {
      if (!this.totalCashback) {
        return {
          valueCurrency: '0',
          valueFiat: null
        };
      }

      return {
        valueCurrency: `${numeralSpaces(
          this.totalCashback.reward,
          '0,0.[0]'
        )} ${this.totalCashback.currency}`,
        valueFiat: `${numeralSpaces(
          this.totalCashback.rewardUsd,
          '0,0.[0]'
        )} USD`
      };
    }
  },
  methods: {
    updateList(): void {
      const { id } = this.selectedCampaign || {};
      if (!id || this.loading) return;

      this.loading = true;

      const params = {
        ...this.searchParams,
        sort: this.options.sortBy[0]
      };

      getPromoCampaignsCashbackUsers(params, id)
        .then((response) => {
          this.usersList = response.content;
          this.total = response.totalElements;
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally(() => {
          this.loading = false;
        });

      getPromoCampaignsCashbackTotal(this.filter.dates, id).then(
        (response) => {
          this.totalCashback = response;
        }
      );
    },

    getReport(): void {
      const params = {
        ...this.searchParams,
        page: 0,
        size: this.total,
        sort: null,
        direction: 'ASC'
      };

      this.isCSVLoading = true;

      getPromoCampaignsCashbackUsersReport(params, this.selectedCampaign?.id)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const date = Object.values(this.filter.dates)
            .filter((el) => el)
            .map((item: string) => item.replaceAll('-', '.'))
            .join('-');
          const dateText = date && `[${date}]`;
          const fileName = `cashback-users-report${dateText}.csv`;

          doDownload(url, fileName);
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally(() => (this.isCSVLoading = false));
    }
  }
};
