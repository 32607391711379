



































import { mapActions } from 'vuex';
import PromoViewCampaigns from '@/views/PromoView/PromoViewCampaigns.vue';

export default {
  name: 'PromoViewTabs',
  components: {
    PromoViewCampaigns
  },
  data(): any {
    return {
      tab: 'campaigns'
    };
  },
  methods: {
    ...mapActions('Promo', ['showCampaignFormDialog'])
  }
};
