import { mapState } from 'vuex';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10,
  sortBy: [null],
  sortDesc: [false]
};

const defaultDates = {
  from: null,
  to: null
};

import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import StatsCard from '@/components/StatsCard.vue';
import CopyAddress from '@/components/CopyAddress.vue';

export default {
  components: {
    VExtendedDataPicker,
    StatsCard,
    CopyAddress
  },
  props: {
    activeTab: {
      type: Boolean,
      default: false
    }
  },

  data(): any {
    return {
      defaultDates,
      filter: {
        operatorUserId: null,
        dates: defaultDates
      },
      search: null,
      options: defaultOptions,
      usersList: [],
      loading: false,
      itemsPerPage: [10, 20, 50],
      total: 0
    };
  },

  watch: {
    watchGroup([openDialog, activeTab]: boolean[]): void {
      if (openDialog && activeTab) {
        this.resetData();
        return;
      }

      this.usersList = [];
    },
    searchParams: {
      handler: 'updateList',
      deep: true
    }
  },

  computed: {
    ...mapState('Promo', ['selectedCampaign', 'dialog']),

    watchGroup(): boolean[] {
      return [this.dialog.participantsList, this.activeTab];
    },

    searchParams(): { [key: string]: string | number } {
      return {
        operatorUserId: this.filter.operatorUserId,
        size: this.options.itemsPerPage,
        page: this.options.page - 1,
        direction: this.options.sortDesc[0] ? 'DESC' : 'ASC',
        ...this.filter.dates
      };
    }
  },

  methods: {
    resetData(): void {
      this.options = { ...defaultOptions };
      this.filter = {
        operatorUserId: null,
        dates: this.defaultDates
      };
      this.search = null;
    },

    clearSearch(): void {
      this.filter.operatorUserId = null;
    },

    forceUpdate(): void {
      this.clearSearch();
      this.$nextTick(this.updateList());
    },

    searchHandler(): void {
      this.filter.operatorUserId = this.search;
    }
  }
};
