import {
  EPromoAccrualCondition,
  EPromoCampaignType,
  ECashbackGameTypes,
  PromoCampaignData,
  IPromoCampaignFormData,
  IPromoCampaignEnabledFields
} from '@/api/schema';
import { fromPercents } from '@/helpers/decimalsHelpers';
import { DateTime } from 'luxon';
import store from '@/store';

const RegistrationRewardIcon = (): any =>
  import(
    '@/assets/images/icons/promoCampaigns/registration-reward-icon.svg?inline'
  );
const KycRewardIcon = (): any =>
  import('@/assets/images/icons/promoCampaigns/kyc-reward-icon.svg?inline');
const CashbackIcon = (): any =>
  import('@/assets/images/icons/promoCampaigns/cashback-icon.svg?inline');
const DepositIcon = (): any =>
  import('@/assets/images/icons/promoCampaigns/deposit-icon.svg?inline');
const TokenPreSaleIcon = (): any =>
  import('@/assets/images/icons/promoCampaigns/token-pre-sale-icon.svg?inline');
const CustomIcon = (): any =>
  import('@/assets/images/icons/promoCampaigns/custom-icon.svg?inline');
const CheckboxCircleIcon = (): any =>
  import('@/assets/images/icons/checkbox-circle-icon.svg?inline');

export interface dataInterface {
  ETabKey: typeof ETabKey;
  EFieldTypeKey: typeof EFieldTypeKey;
  daySelectList: ReadonlyArray<{ text: string; value: string }>;
  timeSelectList: ReadonlyArray<{ text: string; value: number }>;
  gameTypesList: ReadonlyArray<{ text: string; value: string }>;
  currentTabKey: keyof typeof ETabKey;
  formData: typeof defaultFormData;
  enabledFields: typeof defaultEnabledFields;
  formsValid: {
    [key in keyof typeof ETabKey]?: boolean | string;
  };
  isShowCongratulations: boolean;
}

export const ETabKey: { [key: string]: string } = {
  CAMPAIGN_TYPE: 'CAMPAIGN_TYPE',
  CAMPAIGN_NAME: 'CAMPAIGN_NAME',
  REGISTRATION_REWARD: 'REGISTRATION_REWARD',
  KYC_REWARD: 'KYC_REWARD',
  AWARD_PROGRAM: 'AWARD_PROGRAM',
  PRE_SALE_COEFFICIENT: 'PRE_SALE_COEFFICIENT',
  CASHBACK: 'CASHBACK',
  ACCRUAL_FORMAT: 'ACCRUAL_FORMAT',
  ACCRUAL_CONDITION: 'ACCRUAL_CONDITION',
  DEADLINE: 'DEADLINE',
  AUDIENCE: 'AUDIENCE',
  CUSTOM_DEADLINE: 'CUSTOM_DEADLINE',
  CHECK_ALL: 'CHECK_ALL'
};

export const EFieldTypeKey = {
  DAILY_CASHBACK: 'DAILY_CASHBACK',
  WEEKLY_CASHBACK: 'WEEKLY_CASHBACK',
  FIXED_DEPOSIT: 'FIXED_DEPOSIT',
  PERCENTAGE_DEPOSIT: 'PERCENTAGE_DEPOSIT',
  AUDIENCE_ALL_USERS: 'AUDIENCE_ALL_USERS',
  AUDIENCE_NEW_USERS: 'AUDIENCE_NEW_USERS',
  AUDIENCE_CUSTOM: 'AUDIENCE_CUSTOM',
  CUSTOM_DEADLINE: 'CUSTOM_DEADLINE',
  CUSTOM_DURATION: 'CUSTOM_DURATION',
  GREATER_THAN_OR_EQUAL: EPromoAccrualCondition.GREATER_THAN_OR_EQUAL,
  LESS_THAN_OR_EQUAL_TO: EPromoAccrualCondition.LESS_THAN_OR_EQUAL_TO,
  INTERVAL_FROM_AND_TO: EPromoAccrualCondition.INTERVAL_FROM_AND_TO
};

export const audienceTypeNaming: { [key: string]: string } = {
  [EFieldTypeKey.AUDIENCE_ALL_USERS]: 'All users',
  [EFieldTypeKey.AUDIENCE_NEW_USERS]: 'New users',
  [EFieldTypeKey.AUDIENCE_CUSTOM]: 'Custom audience'
};

export const promoCampaignTypeNaming: { [key: string]: string } = {
  [EPromoCampaignType.REGISTRATION_REWARD]: 'Registration reward',
  [EPromoCampaignType.KYC_REWARD]: 'KYC Reward',
  [EPromoCampaignType.CASHBACK]: 'Cashback Reward',
  [EPromoCampaignType.DEPOSIT]: 'Casino Deposit Reward',
  [EPromoCampaignType.TOKEN_PRE_SALE]: 'Token Purchase',
  [EPromoCampaignType.CUSTOM]: 'Custom'
};

export const daySelectList: Array<{ text: string; value: string }> = [
  { text: 'Monday', value: 'MONDAY' },
  { text: 'Tuesday', value: 'TUESDAY' },
  { text: 'Wednesday', value: 'WEDNESDAY' },
  { text: 'Thursday', value: 'THURSDAY' },
  { text: 'Friday', value: 'FRIDAY' },
  { text: 'Saturday', value: 'SATURDAY' },
  { text: 'Sunday', value: 'SUNDAY' }
];
export const timeSelectList: Array<{ text: string; value: number }> = [
  { text: '00:00', value: 0 },
  { text: '01:00', value: 1 },
  { text: '02:00', value: 2 },
  { text: '03:00', value: 3 },
  { text: '04:00', value: 4 },
  { text: '05:00', value: 5 },
  { text: '06:00', value: 6 },
  { text: '07:00', value: 7 },
  { text: '08:00', value: 8 },
  { text: '09:00', value: 9 },
  { text: '10:00', value: 10 },
  { text: '11:00', value: 11 },
  { text: '12:00', value: 12 },
  { text: '13:00', value: 13 },
  { text: '14:00', value: 14 },
  { text: '15:00', value: 15 },
  { text: '16:00', value: 16 },
  { text: '17:00', value: 17 },
  { text: '18:00', value: 18 },
  { text: '19:00', value: 19 },
  { text: '20:00', value: 20 },
  { text: '21:00', value: 21 },
  { text: '22:00', value: 22 },
  { text: '23:00', value: 23 }
];

export const gameTypesList = [
  { text: 'Casino', value: ECashbackGameTypes.CASINO },
  { text: 'Sport', value: ECashbackGameTypes.SPORTS_BOOK }
];

export const defaultFormData: IPromoCampaignFormData = {
  campaignType: null,
  campaignName: null,
  fixedReward: null,
  extraP2eReward: null,
  extraH2eReward: null,
  percentageReward: null,
  dailyCashbackReward: null,
  dailyCashbackTime: null,
  dailyCashbackGameType: null,
  weeklyCashbackReward: null,
  weeklyCashbackDay: null,
  weeklyCashbackTime: null,
  weeklyCashbackGameType: null,
  deadlineDate: null,
  durationTime: null,
  maxUsersCount: null,
  greaterAccrualCondition: null,
  lessAccrualCondition: null,
  intervalFromAccrualCondition: null,
  intervalToAccrualCondition: null,
  dailyLimitReward: null,
  weeklyLimitReward: null,
  fixedAccrualLimitReward: null,
  percentageAccrualLimitReward: null,
  preSaleCoefficient: null,
  audience: null,
  allUsers: true,
  registeredAfter: null,
  startDate: null
};
export const defaultEnabledFields: IPromoCampaignEnabledFields = {
  fixedTokensReward: false,
  extraP2eReward: false,
  extraH2eReward: false,
  maxUsersCount: false,
  cashbackType: '',
  accrualFormatType: '',
  accrualConditionType: '',
  customPeriodType: '',
  audience: EFieldTypeKey.AUDIENCE_ALL_USERS,
  dailyCashbackGameType: [
    ECashbackGameTypes.CASINO,
    ECashbackGameTypes.SPORTS_BOOK
  ],
  weeklyCashbackGameType: [
    ECashbackGameTypes.CASINO,
    ECashbackGameTypes.SPORTS_BOOK
  ]
};
export const defaultFormsValidValue = {
  fixedTokensReward: false,
  extraP2eReward: false,
  extraH2eReward: false,
  maxUsersCount: false,
  cashbackType: '',
  accrualFormatType: '',
  accrualConditionType: '',
  customPeriodType: ''
};

export const mapRequestKey = {
  campaignName: 'name',
  fixedReward: 'fixedAmountReward',
  extraP2eReward: 'p2eMultiplierReward',
  extraH2eReward: 'stakingLimitCoefficientReward',
  dailyCashbackReward: 'dailyCashbackReward',
  dailyCashbackTime: 'cashbackTime',
  weeklyCashbackReward: 'weeklyCashbackReward',
  weeklyCashbackDay: 'dayOfWeek',
  weeklyCashbackTime: 'weeklyCashbackTime',
  deadlineDate: 'expirationDate',
  startDate: 'startDate',
  durationTime: 'duration',
  maxUsersCount: 'maxUserCount',
  percentageReward: 'percentAmountReward',
  greaterAccrualCondition: 'conditionMin',
  lessAccrualCondition: 'conditionMin',
  intervalFromAccrualCondition: 'conditionMin',
  intervalToAccrualCondition: 'conditionMax',
  accrualCondition: 'accrualCondition',
  isRegistrationReward: 'signUpRule',
  isKycReward: 'kycRule',
  dailyLimitReward: 'dailyLimit',
  weeklyLimitReward: 'weeklyLimit',
  fixedAccrualLimitReward: 'depositLimit',
  percentageAccrualLimitReward: 'depositLimit',
  preSaleCoefficient: 'preSaleCoefficient',
  registeredAfter: 'registeredAfter',
  allUsers: 'allUsers',
  dailyCashbackGameType: 'cashbackGameType',
  weeklyCashbackGameType: 'cashbackGameType'
};

export const keysToConvertToPercentage = [
  mapRequestKey.dailyCashbackReward,
  mapRequestKey.weeklyCashbackReward,
  mapRequestKey.percentageReward
];

export const getTabKeysList = (campaignType: string): string[] => {
  const tabKeysForCurrentCampaignType = {
    [EPromoCampaignType.REGISTRATION_REWARD]: [ETabKey.REGISTRATION_REWARD],
    [EPromoCampaignType.KYC_REWARD]: [ETabKey.AWARD_PROGRAM],
    [EPromoCampaignType.CASHBACK]: [
      ETabKey.CASHBACK,
      ETabKey.AUDIENCE,
      ETabKey.DEADLINE
    ],
    [EPromoCampaignType.DEPOSIT]: [
      ETabKey.ACCRUAL_FORMAT,
      ETabKey.ACCRUAL_CONDITION,
      ETabKey.DEADLINE
    ],
    [EPromoCampaignType.TOKEN_PRE_SALE]: [
      ETabKey.PRE_SALE_COEFFICIENT,
      ETabKey.AUDIENCE,
      ETabKey.CUSTOM_DEADLINE
    ],
    [EPromoCampaignType.CUSTOM]: [
      ETabKey.AWARD_PROGRAM,
      ETabKey.CUSTOM_DEADLINE
    ]
  };

  return [
    ETabKey.CAMPAIGN_TYPE,
    ETabKey.CAMPAIGN_NAME,
    ...(tabKeysForCurrentCampaignType[campaignType] || []),
    ETabKey.CHECK_ALL
  ];
};

export const getTabsContent = (
  data: typeof defaultFormData,
  enabledFields: typeof defaultEnabledFields
): any => {
  const tokenSymbol = store.state.Auth.tokenSymbol;

  return {
    [ETabKey.CAMPAIGN_TYPE]: {
      infoBlock: {
        disabledProgress: true,
        title: 'Create a marketing campaign to reward a specific audience.',
        subTitle: ' *You can choose only one program from the options provided.'
      },
      formContent: {
        campaignsDataList: [
          {
            title:
              promoCampaignTypeNaming[EPromoCampaignType.REGISTRATION_REWARD],
            description:
              'User receives specified amount of tokens after registration',
            icon: RegistrationRewardIcon,
            actionIcon: CheckboxCircleIcon,
            isDisabled: true,
            key: EPromoCampaignType.REGISTRATION_REWARD
          },
          {
            title: promoCampaignTypeNaming[EPromoCampaignType.KYC_REWARD],
            description:
              'User receives specified amount of tokens when verification completed',
            icon: KycRewardIcon,
            key: EPromoCampaignType.KYC_REWARD
          },
          {
            title: promoCampaignTypeNaming[EPromoCampaignType.CASHBACK],
            description: 'User receives share from own GGR',
            icon: CashbackIcon,
            key: EPromoCampaignType.CASHBACK
          },
          {
            title: promoCampaignTypeNaming[EPromoCampaignType.DEPOSIT],
            // tooltip: 'test',
            description:
              'Give rewards to users when they achieve desired deposit volume in casino',
            icon: DepositIcon,
            key: EPromoCampaignType.DEPOSIT
          },
          {
            title: promoCampaignTypeNaming[EPromoCampaignType.TOKEN_PRE_SALE],
            // tooltip: 'test',
            description: 'Allow user to purchase desired amount of tokens',
            icon: TokenPreSaleIcon,
            isDisabled: store.getters['ClientsInfo/isListed'],
            key: EPromoCampaignType.TOKEN_PRE_SALE
          },
          {
            title: promoCampaignTypeNaming[EPromoCampaignType.CUSTOM],
            // tooltip: 'test',
            description:
              'Users can add more tokens to high-yeld Hold 2 Earn programs or get extra Play to Earn.',
            icon: CustomIcon,
            key: EPromoCampaignType.CUSTOM
          }
        ]
      }
    },
    [ETabKey.CAMPAIGN_NAME]: {
      infoBlock: {
        title: 'Come up with a name for your campaign'
      }
    },
    [ETabKey.REGISTRATION_REWARD]: {
      infoBlock: {
        title: 'Specify the reward amount for one of the items'
      }
    },
    [ETabKey.PRE_SALE_COEFFICIENT]: {
      infoBlock: {
        title: 'Choose an award'
      }
    },
    [ETabKey.AWARD_PROGRAM]: {
      infoBlock: {
        title: 'Specify the reward amount for one of the items'
      }
    },
    [ETabKey.CASHBACK]: {
      infoBlock: {
        title: 'Specify the reward amount for one of the items'
      }
    },
    [ETabKey.ACCRUAL_FORMAT]: {
      infoBlock: {
        title: 'Accrual format'
      }
    },
    [ETabKey.ACCRUAL_CONDITION]: {
      infoBlock: {
        title: 'Accrual condition'
      }
    },
    [ETabKey.DEADLINE]: {
      infoBlock: {
        title: 'Deadline'
      }
    },
    [ETabKey.AUDIENCE]: {
      infoBlock: {
        title: 'Choose audience'
      }
    },
    [ETabKey.CUSTOM_DEADLINE]: {
      infoBlock: {
        title: "Let's choose start and end dates for your campaign"
      }
    },
    [ETabKey.CHECK_ALL]: {
      infoBlock: {
        disabledProgress: true,
        title: 'Check all data before creating a campaign'
      },
      [EPromoCampaignType.REGISTRATION_REWARD]: [
        {
          content: [
            {
              title: 'Reward type',
              value: promoCampaignTypeNaming[data.campaignType] || ''
            }
          ]
        },
        {
          content: [
            {
              title: 'Campaign Name',
              value: data.campaignName
            }
          ],
          stepNumber: 1
        },
        {
          content: [
            {
              title: `Reward amount, ${tokenSymbol}`,
              value: data.fixedReward
            }
          ],
          stepNumber: 2
        }
      ],
      [EPromoCampaignType.KYC_REWARD]: [
        {
          content: [
            {
              title: 'Reward type',
              value: promoCampaignTypeNaming[data.campaignType] || ''
            }
          ]
        },
        {
          content: [
            {
              title: 'Campaign Name',
              value: data.campaignName
            }
          ],
          stepNumber: 1
        },
        {
          hide: !enabledFields.fixedTokensReward,
          content: [
            {
              title: 'Award program',
              value: 'Amount of Tokens'
            },
            {
              title: `Amount, ${tokenSymbol}`,
              value: data.fixedReward
            }
          ],
          stepNumber: 2
        },
        {
          hide: !enabledFields.extraP2eReward,
          content: [
            {
              title: 'Award program',
              value: 'Play To Earn Multiplier'
            },
            {
              title: `Multiplier`,
              value: 'x' + data.extraP2eReward
            }
          ],
          stepNumber: 2
        },
        {
          hide: !enabledFields.extraH2eReward,
          content: [
            {
              title: 'Award program',
              value: 'Hold To Earn Coefficient'
            },
            {
              title: `Coefficient`,
              value: data.extraH2eReward
            }
          ],
          stepNumber: 2
        }
      ].filter((listItem) => !listItem.hide),
      [EPromoCampaignType.CASHBACK]: [
        {
          content: [
            {
              title: 'Reward type',
              value: promoCampaignTypeNaming[data.campaignType] || ''
            }
          ]
        },
        {
          content: [
            {
              title: 'Campaign Name',
              value: data.campaignName
            }
          ],
          stepNumber: 1
        },
        {
          hide: enabledFields.cashbackType !== EFieldTypeKey.DAILY_CASHBACK,
          content: [
            {
              title: 'Daily Cashback, %',
              value: data.dailyCashbackReward
            },
            {
              title: 'Distribution time, UTC',
              value: timeSelectList.find(
                (v) => v.value === data.dailyCashbackTime
              )?.text
            }
          ],
          stepNumber: 2
        },
        {
          hide: enabledFields.cashbackType !== EFieldTypeKey.WEEKLY_CASHBACK,
          content: [
            {
              title: 'Weekly Cashback, %',
              value: data.weeklyCashbackReward
            },
            {
              title: `Day of Week`,
              value: daySelectList.find(
                (v) => v.value === data.weeklyCashbackDay
              )?.text
            },
            {
              title: 'Distribution time, UTC',
              value: timeSelectList.find(
                (v) => v.value === data.weeklyCashbackTime
              )?.text
            }
          ],
          stepNumber: 2
        },
        {
          hide: !(
            enabledFields.cashbackType === EFieldTypeKey.DAILY_CASHBACK &&
            data.dailyLimitReward
          ),
          content: [
            {
              title: `Max reward per user, ${tokenSymbol}`,
              value: data.dailyLimitReward
            }
          ],
          stepNumber: 2
        },
        {
          hide: !(
            enabledFields.cashbackType === EFieldTypeKey.WEEKLY_CASHBACK &&
            data.weeklyLimitReward
          ),
          content: [
            {
              title: `Max reward per user, ${tokenSymbol}`,
              value: data.weeklyLimitReward
            }
          ],
          stepNumber: 2
        },
        {
          hide:
            !enabledFields.weeklyCashbackGameType &&
            !enabledFields.dailyCashbackGameType,
          content: [
            {
              title: 'Game Type',
              value: gameTypesList
                .filter((el) => {
                  const gameTypes =
                    enabledFields.cashbackType === EFieldTypeKey.WEEKLY_CASHBACK
                      ? enabledFields.weeklyCashbackGameType
                      : enabledFields.dailyCashbackGameType;

                  return (gameTypes || []).includes(el.value);
                })
                .map((el) => el.text)
                .join(', ')
            }
          ],
          stepNumber: 2
        },
        {
          content: [
            {
              title: 'Audience',
              value: audienceTypeNaming[enabledFields.audience]
            }
          ],
          stepNumber: 3
        },
        {
          hide: !data.startDate,
          content: [
            {
              title: 'Start Date',
              value: data.startDate
            }
          ],
          stepNumber: 4
        },
        {
          hide: !data.deadlineDate,
          content: [
            {
              title: 'End Date',
              value: data.deadlineDate
            }
          ],
          stepNumber: 4
        }
      ].filter((listItem) => !listItem.hide),
      [EPromoCampaignType.DEPOSIT]: [
        {
          content: [
            {
              title: 'Reward type',
              value: promoCampaignTypeNaming[data.campaignType] || ''
            }
          ]
        },
        {
          content: [
            {
              title: 'Campaign Name',
              value: data.campaignName
            }
          ],
          stepNumber: 1
        },
        {
          content: [
            {
              hide:
                enabledFields.accrualFormatType !== EFieldTypeKey.FIXED_DEPOSIT,
              title: 'Reward amount',
              value: 'Fixed amount'
            },
            {
              hide:
                enabledFields.accrualFormatType !== EFieldTypeKey.FIXED_DEPOSIT,
              title: `Amount, ${tokenSymbol}`,
              value: data.fixedReward
            },
            {
              hide:
                enabledFields.accrualFormatType !==
                EFieldTypeKey.PERCENTAGE_DEPOSIT,
              title: 'Reward amount',
              value: 'Percentage of the deposit'
            },
            {
              hide:
                enabledFields.accrualFormatType !==
                EFieldTypeKey.PERCENTAGE_DEPOSIT,
              title: `Amount, %`,
              value: data.percentageReward
            }
          ].filter((columnItem) => !columnItem.hide),
          stepNumber: 2
        },
        {
          hide: !(
            enabledFields.accrualFormatType === EFieldTypeKey.FIXED_DEPOSIT &&
            data.fixedAccrualLimitReward
          ),
          content: [
            {
              title: 'Limit per user',
              value: data.fixedAccrualLimitReward
            }
          ],
          stepNumber: 2
        },
        {
          hide: !(
            enabledFields.accrualFormatType ===
              EFieldTypeKey.PERCENTAGE_DEPOSIT &&
            data.percentageAccrualLimitReward
          ),
          content: [
            {
              title: 'Limit per user',
              value: data.percentageAccrualLimitReward
            }
          ],
          stepNumber: 2
        },
        {
          content: [
            {
              hide:
                enabledFields.accrualConditionType !==
                EFieldTypeKey.GREATER_THAN_OR_EQUAL,
              title: 'Accrual condition',
              value: `>= ${data.greaterAccrualCondition}`
            },
            {
              hide:
                enabledFields.accrualConditionType !==
                EFieldTypeKey.LESS_THAN_OR_EQUAL_TO,
              title: 'Accrual condition',
              value: `<= ${data.lessAccrualCondition}`
            },
            {
              hide:
                enabledFields.accrualConditionType !==
                EFieldTypeKey.INTERVAL_FROM_AND_TO,
              title: 'Accrual condition',
              value: `from: ${data.intervalFromAccrualCondition} to: ${data.intervalToAccrualCondition}`
            }
          ].filter((columnItem) => !columnItem.hide),
          stepNumber: 3
        },
        {
          content: [
            {
              title: 'Deadline',
              value: data.deadlineDate
            }
          ],
          stepNumber: 4
        }
      ].filter((listItem) => !listItem.hide),
      [EPromoCampaignType.TOKEN_PRE_SALE]: [
        {
          content: [
            {
              title: 'Reward type',
              value: promoCampaignTypeNaming[data.campaignType] || ''
            }
          ]
        },
        {
          content: [
            {
              title: 'Campaign Name',
              value: data.campaignName
            }
          ],
          stepNumber: 1
        },
        {
          content: [
            {
              title: 'Award',
              value: 'Token Purchase Coefficient'
            },
            {
              title: 'Token Purchase Coefficient',
              value: data.preSaleCoefficient
            }
          ],
          stepNumber: 2
        },
        {
          content: [
            {
              title: 'Audience',
              value: audienceTypeNaming[enabledFields.audience]
            }
          ],
          stepNumber: 3
        },
        {
          content: [
            {
              hide:
                enabledFields.customPeriodType !==
                EFieldTypeKey.CUSTOM_DEADLINE,
              title: 'Deadline',
              value: data.deadlineDate
            },
            {
              hide:
                enabledFields.customPeriodType !==
                EFieldTypeKey.CUSTOM_DURATION,
              title: 'Duration, (hours)',
              value: data.durationTime
            },
            {
              hide: !enabledFields.maxUsersCount,
              title: 'Maximum Number of Users',
              value: data.maxUsersCount
            }
          ].filter((columnItem) => !columnItem.hide),
          stepNumber: 4
        }
      ].filter((listItem) => listItem.content?.length !== 0),
      [EPromoCampaignType.CUSTOM]: [
        {
          content: [
            {
              title: 'Reward type',
              value: promoCampaignTypeNaming[data.campaignType] || ''
            }
          ]
        },
        {
          content: [
            {
              title: 'Campaign Name',
              value: data.campaignName
            }
          ],
          stepNumber: 1
        },
        {
          hide: !enabledFields.fixedTokensReward,
          content: [
            {
              title: 'Award program',
              value: 'Amount of Tokens'
            },
            {
              title: `Amount, ${tokenSymbol}`,
              value: data.fixedReward
            }
          ],
          stepNumber: 2
        },
        {
          hide: !enabledFields.extraP2eReward,
          content: [
            {
              title: 'Award program',
              value: 'Play To Earn Multiplier'
            },
            {
              title: `Multiplier`,
              value: 'x' + data.extraP2eReward
            }
          ],
          stepNumber: 2
        },
        {
          hide: !enabledFields.extraH2eReward,
          content: [
            {
              title: 'Award program',
              value: 'Hold To Earn Coefficient'
            },
            {
              title: `Coefficient`,
              value: data.extraH2eReward
            }
          ],
          stepNumber: 2
        },
        {
          content: [
            {
              hide:
                enabledFields.customPeriodType !==
                EFieldTypeKey.CUSTOM_DEADLINE,
              title: 'Deadline',
              value: data.deadlineDate
            },
            {
              hide:
                enabledFields.customPeriodType !==
                EFieldTypeKey.CUSTOM_DURATION,
              title: 'Duration, (hours)',
              value: data.durationTime
            },
            {
              hide: !enabledFields.maxUsersCount,
              title: 'Maximum Number of Users',
              value: data.maxUsersCount
            }
          ].filter((columnItem) => !columnItem.hide),
          stepNumber: 3
        }
      ].filter((listItem) => !listItem.hide && listItem.content?.length !== 0)
    }
  };
};

interface PayloadDataMapItem {
  requestKey: string;
  dependency: boolean;
  value: string | number | boolean | string[];
}

export const getPayloadDataMap = (
  data: typeof defaultFormData,
  dependencyFields: typeof defaultEnabledFields
): { [key: string]: PayloadDataMapItem[] } => {
  return {
    [EPromoCampaignType.REGISTRATION_REWARD]: [
      {
        requestKey: mapRequestKey.campaignName,
        dependency: true,
        value: data.campaignName
      },
      {
        requestKey: mapRequestKey.fixedReward,
        dependency: true,
        value: data.fixedReward
      },
      {
        requestKey: mapRequestKey.isRegistrationReward,
        dependency: true,
        value: true
      }
    ],
    [EPromoCampaignType.KYC_REWARD]: [
      {
        requestKey: mapRequestKey.campaignName,
        dependency: true,
        value: data.campaignName
      },
      {
        requestKey: mapRequestKey.fixedReward,
        dependency: !!dependencyFields.fixedTokensReward,
        value: data.fixedReward
      },
      {
        requestKey: mapRequestKey.extraH2eReward,
        dependency: !!dependencyFields.extraH2eReward,
        value: data.extraH2eReward
      },
      {
        requestKey: mapRequestKey.extraP2eReward,
        dependency: !!dependencyFields.extraP2eReward,
        value: data.extraP2eReward
      },
      {
        requestKey: mapRequestKey.isKycReward,
        dependency: true,
        value: true
      }
    ],
    [EPromoCampaignType.CASHBACK]: [
      {
        requestKey: mapRequestKey.campaignName,
        dependency: true,
        value: data.campaignName
      },
      {
        requestKey: mapRequestKey.dailyCashbackReward,
        dependency:
          dependencyFields.cashbackType === EFieldTypeKey.DAILY_CASHBACK,
        value: fromPercents(data.dailyCashbackReward)
      },
      {
        requestKey: mapRequestKey.dailyCashbackTime,
        dependency:
          dependencyFields.cashbackType === EFieldTypeKey.DAILY_CASHBACK,
        value: data.dailyCashbackTime
      },
      {
        requestKey: mapRequestKey.dailyLimitReward,
        dependency: !!data.dailyLimitReward,
        value: data.dailyLimitReward
      },
      {
        requestKey: mapRequestKey.dailyCashbackGameType,
        dependency:
          dependencyFields.cashbackType === EFieldTypeKey.DAILY_CASHBACK,
        value: dependencyFields.dailyCashbackGameType
      },
      {
        requestKey: mapRequestKey.weeklyCashbackReward,
        dependency:
          dependencyFields.cashbackType === EFieldTypeKey.WEEKLY_CASHBACK,
        value: fromPercents(data.weeklyCashbackReward)
      },
      {
        requestKey: mapRequestKey.weeklyCashbackDay,
        dependency:
          dependencyFields.cashbackType === EFieldTypeKey.WEEKLY_CASHBACK,
        value: data.weeklyCashbackDay
      },
      {
        requestKey: mapRequestKey.weeklyCashbackTime,
        dependency:
          dependencyFields.cashbackType === EFieldTypeKey.WEEKLY_CASHBACK,
        value: data.weeklyCashbackTime
      },
      {
        requestKey: mapRequestKey.weeklyLimitReward,
        dependency: !!data.weeklyLimitReward,
        value: data.weeklyLimitReward
      },
      {
        requestKey: mapRequestKey.weeklyCashbackGameType,
        dependency:
          dependencyFields.cashbackType === EFieldTypeKey.WEEKLY_CASHBACK,
        value: dependencyFields.weeklyCashbackGameType
      },
      {
        requestKey: mapRequestKey.allUsers,
        dependency:
          dependencyFields.audience === EFieldTypeKey.AUDIENCE_ALL_USERS,
        value: true
      },
      {
        requestKey: mapRequestKey.registeredAfter,
        dependency:
          dependencyFields.audience === EFieldTypeKey.AUDIENCE_NEW_USERS,
        value: DateTime.now().toISODate()
      },
      {
        requestKey: mapRequestKey.startDate,
        dependency: !!data.startDate,
        value: data.startDate
      },
      {
        requestKey: mapRequestKey.deadlineDate,
        dependency: !!data.deadlineDate,
        value: data.deadlineDate
      }
    ],
    [EPromoCampaignType.DEPOSIT]: [
      {
        requestKey: mapRequestKey.campaignName,
        dependency: true,
        value: data.campaignName
      },
      {
        requestKey: mapRequestKey.fixedReward,
        dependency:
          dependencyFields.accrualFormatType === EFieldTypeKey.FIXED_DEPOSIT,
        value: data.fixedReward
      },
      {
        requestKey: mapRequestKey.fixedAccrualLimitReward,
        dependency:
          dependencyFields.accrualFormatType === EFieldTypeKey.FIXED_DEPOSIT &&
          !!data.fixedAccrualLimitReward,
        value: data.fixedAccrualLimitReward
      },
      {
        requestKey: mapRequestKey.percentageReward,
        dependency:
          dependencyFields.accrualFormatType ===
          EFieldTypeKey.PERCENTAGE_DEPOSIT,
        value: fromPercents(data.percentageReward)
      },
      {
        requestKey: mapRequestKey.percentageAccrualLimitReward,
        dependency:
          dependencyFields.accrualFormatType ===
            EFieldTypeKey.PERCENTAGE_DEPOSIT &&
          !!data.percentageAccrualLimitReward,
        value: data.percentageAccrualLimitReward
      },
      {
        requestKey: mapRequestKey.accrualCondition,
        dependency: true,
        value: dependencyFields.accrualConditionType
      },
      {
        requestKey: mapRequestKey.greaterAccrualCondition,
        dependency:
          dependencyFields.accrualConditionType ===
          EFieldTypeKey.GREATER_THAN_OR_EQUAL,
        value: data.greaterAccrualCondition
      },
      {
        requestKey: mapRequestKey.lessAccrualCondition,
        dependency:
          dependencyFields.accrualConditionType ===
          EFieldTypeKey.LESS_THAN_OR_EQUAL_TO,
        value: data.lessAccrualCondition
      },
      {
        requestKey: mapRequestKey.intervalFromAccrualCondition,
        dependency:
          dependencyFields.accrualConditionType ===
          EFieldTypeKey.INTERVAL_FROM_AND_TO,
        value: data.intervalFromAccrualCondition
      },
      {
        requestKey: mapRequestKey.intervalToAccrualCondition,
        dependency:
          dependencyFields.accrualConditionType ===
          EFieldTypeKey.INTERVAL_FROM_AND_TO,
        value: data.intervalToAccrualCondition
      },
      {
        requestKey: mapRequestKey.deadlineDate,
        dependency: true,
        value: data.deadlineDate
      }
    ],
    [EPromoCampaignType.TOKEN_PRE_SALE]: [
      {
        requestKey: mapRequestKey.campaignName,
        dependency: true,
        value: data.campaignName
      },
      {
        requestKey: mapRequestKey.preSaleCoefficient,
        dependency: true,
        value: data.preSaleCoefficient
      },
      {
        requestKey: mapRequestKey.allUsers,
        dependency:
          dependencyFields.audience === EFieldTypeKey.AUDIENCE_ALL_USERS,
        value: true
      },
      {
        requestKey: mapRequestKey.registeredAfter,
        dependency:
          dependencyFields.audience === EFieldTypeKey.AUDIENCE_NEW_USERS,
        value: DateTime.now().toISODate()
      },
      {
        requestKey: mapRequestKey.deadlineDate,
        dependency:
          dependencyFields.customPeriodType === EFieldTypeKey.CUSTOM_DEADLINE,
        value: data.deadlineDate
      },
      {
        requestKey: mapRequestKey.durationTime,
        dependency:
          dependencyFields.customPeriodType === EFieldTypeKey.CUSTOM_DURATION,
        value: data.durationTime
      },
      {
        requestKey: mapRequestKey.maxUsersCount,
        dependency: !!dependencyFields.maxUsersCount,
        value: data.maxUsersCount
      }
    ],
    [EPromoCampaignType.CUSTOM]: [
      {
        requestKey: mapRequestKey.campaignName,
        dependency: true,
        value: data.campaignName
      },
      {
        requestKey: mapRequestKey.fixedReward,
        dependency: !!dependencyFields.fixedTokensReward,
        value: data.fixedReward
      },
      {
        requestKey: mapRequestKey.extraH2eReward,
        dependency: !!dependencyFields.extraH2eReward,
        value: data.extraH2eReward
      },
      {
        requestKey: mapRequestKey.extraP2eReward,
        dependency: !!dependencyFields.extraP2eReward,
        value: data.extraP2eReward
      },
      {
        requestKey: mapRequestKey.deadlineDate,
        dependency:
          dependencyFields.customPeriodType === EFieldTypeKey.CUSTOM_DEADLINE,
        value: data.deadlineDate
      },
      {
        requestKey: mapRequestKey.durationTime,
        dependency:
          dependencyFields.customPeriodType === EFieldTypeKey.CUSTOM_DURATION,
        value: data.durationTime
      },
      {
        requestKey: mapRequestKey.maxUsersCount,
        dependency: !!dependencyFields.maxUsersCount,
        value: data.maxUsersCount
      }
    ]
  };
};

export const getCampaignTypeFieldsConfig = (
  campaignData: PromoCampaignData,
  campaignType: string
): any => {
  const campaignTypeMapConfig = {
    [EPromoCampaignType.KYC_REWARD]: {
      fixedTokensReward: !!campaignData.fixedAmountReward,
      extraP2eReward: !!campaignData.p2eMultiplierReward,
      extraH2eReward: !!campaignData.stakingLimitCoefficientReward
    },
    [EPromoCampaignType.CASHBACK]: {
      cashbackType: campaignData.dailyCashbackReward
        ? EFieldTypeKey.DAILY_CASHBACK
        : EFieldTypeKey.WEEKLY_CASHBACK,
      audience: campaignData.allUsers
        ? EFieldTypeKey.AUDIENCE_ALL_USERS
        : campaignData.registeredAfter
        ? EFieldTypeKey.AUDIENCE_NEW_USERS
        : EFieldTypeKey.AUDIENCE_CUSTOM,
      dailyCashbackGameType:
        campaignData.dailyCashbackReward && campaignData.cashbackGameTypes,
      weeklyCashbackGameType:
        campaignData.weeklyCashbackReward && campaignData.cashbackGameTypes
    },
    [EPromoCampaignType.DEPOSIT]: {
      accrualFormatType: campaignData.fixedAmountReward
        ? EFieldTypeKey.FIXED_DEPOSIT
        : EFieldTypeKey.PERCENTAGE_DEPOSIT,
      accrualConditionType: campaignData.accrualCondition
    },
    [EPromoCampaignType.TOKEN_PRE_SALE]: {
      maxUsersCount: !!campaignData.maxUserCount,
      audience: campaignData.allUsers
        ? EFieldTypeKey.AUDIENCE_ALL_USERS
        : campaignData.registeredAfter
        ? EFieldTypeKey.AUDIENCE_NEW_USERS
        : EFieldTypeKey.AUDIENCE_CUSTOM,
      customPeriodType: campaignData.expirationDate
        ? EFieldTypeKey.CUSTOM_DEADLINE
        : campaignData.duration
        ? EFieldTypeKey.CUSTOM_DURATION
        : ''
    },
    [EPromoCampaignType.CUSTOM]: {
      fixedTokensReward: !!campaignData.fixedAmountReward,
      extraP2eReward: !!campaignData.p2eMultiplierReward,
      extraH2eReward: !!campaignData.stakingLimitCoefficientReward,
      maxUsersCount: !!campaignData.maxUserCount,
      customPeriodType: campaignData.expirationDate
        ? EFieldTypeKey.CUSTOM_DEADLINE
        : campaignData.duration
        ? EFieldTypeKey.CUSTOM_DURATION
        : ''
    }
  };

  return campaignTypeMapConfig[campaignType];
};
