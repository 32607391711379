




















import { PropType } from 'vue';

interface IInfoForm {
  disabledProgress: boolean;
  title: string;
  subTitle: string;
}
export default {
  name: 'PromoCampaignsFormInfo',
  props: {
    isViewMode: {
      type: Boolean,
      default: false
    },
    currentTabIndex: {
      type: Number,
      default: 0
    },
    tabKeysLength: {
      type: Number,
      default: 0
    },
    info: {
      type: Object as PropType<IInfoForm>,
      default: (): any => ({})
    }
  }
};
