var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',{staticClass:"rounded"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12"}},[_c('v-select',{staticClass:"pt-0 search-type-select",attrs:{"items":_vm.selectItems,"outlined":"","dense":"","hide-details":"","placeholder":"Campaign Status","clearable":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('v-extended-data-picker',{staticClass:"ml-3",attrs:{"display-format":"dd MMMM","max-today":"","range":"","show-reset":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1)],1),_c('v-data-table-with-pagination',{staticClass:"campaigns-table",attrs:{"headers":_vm.headers,"items":_vm.campaigns.items,"loading":_vm.loading.campaigns,"server-items-length":_vm.campaigns.total,"options":_vm.options,"must-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s('#' + item.id))]}},{key:"item.sumFixedAmountReward",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(_vm.getTotalReward(item),'0,0.[00]'))+" "+_vm._s(_vm.tokenSymbol))]}},{key:"item.status-select",fn:function(ref){
var item = ref.item;
return [(_vm.$role.can.update('promo'))?_c('v-select',{staticClass:"my-3",attrs:{"hide-details":"","solo":"","dense":"","single-line":"","items":_vm.getSelectData(item),"value":item.status},on:{"input":function($event){return _vm.changeStatusHandler($event, item)}}}):_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.$role.can.update('promo') || _vm.isActive(item))?_c('v-btn',{attrs:{"icon":"","text":"","small":""},on:{"click":function($event){return _vm.showCampaignFormDialog({status: true, data: item})}}},[_c('v-icon',{attrs:{"title":_vm.isActive(item) ? 'See details' : 'Edit campaign'}},[_vm._v(_vm._s(("mdi-" + (_vm.isActive(item) ? 'file-eye-outline' : 'pencil-outline'))))])],1):_vm._e(),(_vm.$role.can.update('promo'))?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"d-flex align-center cursor-pointer",on:{"click":function($event){return _vm.showParticipantsDialog({status: true, data: item})}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-account-group-outline")]),_vm._v("View user list")],1),(_vm.$role.can.update('promo'))?_c('v-list-item',{staticClass:"d-flex align-center cursor-pointer",on:{"click":function($event){return _vm.showGiveRewardDialog({ status: true, data: item })}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-account-multiple-plus-outline")]),_vm._v("Add users")],1):_vm._e(),_c('v-list-item',{staticClass:"d-flex align-center cursor-pointer",on:{"click":function($event){return _vm.showRestrictionDialog({status: true, data: item})}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-account-arrow-right-outline")]),_vm._v("Users allowed")],1),(_vm.$role.can.update('promo') && (!!item.dailyCashbackReward || !!item.weeklyCashbackReward))?_c('v-list-item',{staticClass:"d-flex align-center cursor-pointer",on:{"click":function($event){return _vm.showGamesBlacklistDialog({status: true, data: item})}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-slot-machine")]),_vm._v("Games Blacklist")],1):_vm._e(),(!_vm.isActive(item) && _vm.$role.can.delete('promo'))?_c('v-list-item',{staticClass:"d-flex align-center cursor-pointer",on:{"click":function($event){return _vm.showDeleteDialog({status: true, data: item})}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-delete-outline")]),_vm._v("Delete campaign")],1):_vm._e()],1)],1):_vm._e()],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.createdAt.slice(0, 16) || ''))]}}])})],1),_c('ConfirmationDialog',{attrs:{"value":_vm.dialog.deleteCampaign,"data":_vm.deleteCampaignDialogData},on:{"close":function($event){return _vm.showDeleteDialog({status: false})},"submit":_vm.deleteCampaignHandler}}),_c('ConfirmationDialog',{attrs:{"value":_vm.dialog.updateConfirmation,"data":_vm.updateCampaignDialogData},on:{"close":_vm.closeCampaignUpdateConfirmation,"submit":_vm.saveCampaignAction}}),_c('ConfirmationDialog',{attrs:{"value":_vm.dialog.updateStatus,"data":_vm.getUpdateStatusDialogData()},on:{"close":function($event){return _vm.showUpdateStatusConfirmationDialog({status: false})},"submit":_vm.changeStatusHandler}}),_c('PromoCampaignDialog',{ref:"promoCampaignForm",attrs:{"saving":_vm.savingCampaign},on:{"close":_vm.clearUpdateCampaignData,"submit":_vm.saveCampaignAction}}),_c('PromoViewAddUsersDialog',{attrs:{"saving":_vm.savingRestrictionList},on:{"close":_vm.clearUpdateCampaignData}}),_c('PromoViewGamesBlacklistDialog',{attrs:{"value":_vm.dialog.gamesBlacklist},on:{"close":function($event){return _vm.showGamesBlacklistDialog({status: false, data: null})}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }