







































import { mapActions, mapMutations, mapState } from 'vuex';
import PromoViewTabs from '@/views/PromoView/PromoViewTabs.vue';
import PromoViewParticipantsDialog from '@/views/PromoView/PromoViewParticipantsDialog/PromoViewParticipantsDialog.vue';
import SelectUsersDialog from '@/components/users/SelectUsersDialog.vue';
import { SelectUsersDialogPayload } from '@/api/schema';

export default {
  name: 'PromoView',
  components: {
    PromoViewTabs,
    SelectUsersDialog,
    PromoViewParticipantsDialog
  },
  computed: {
    ...mapState('Promo', ['selectedUsers', 'dialog'])
  },
  methods: {
    ...mapActions('Promo', ['showUserSelectDialog']),
    ...mapMutations('Promo', ['setSelectedUsers', 'resetPromoState']),
    saveSelectedUsersHandler(data: SelectUsersDialogPayload): void {
      this.setSelectedUsers(data.operatorUserIds);
      this.showUserSelectDialog(false);
    }
  },
  beforeDestroy(): void {
    this.resetPromoState();
  }
};
