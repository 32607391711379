
















































































































import { mapActions, mapState } from 'vuex';
import { toPercents } from '@/helpers/decimalsHelpers';

import SuccessPopupCheck from '@/assets/images/notification/success-popup-check.svg?inline';

import { EPromoCampaignType, PromoCampaignData } from '@/api/schema';

import {
  dataInterface,
  ETabKey,
  EFieldTypeKey,
  mapRequestKey,
  daySelectList,
  timeSelectList,
  gameTypesList,
  keysToConvertToPercentage,
  defaultEnabledFields,
  defaultFormData,
  defaultFormsValidValue,
  getTabKeysList,
  getPayloadDataMap,
  getCampaignTypeFieldsConfig,
  getTabsContent
} from '@/views/PromoView/PromoCampaignDialog/PromoCampaignFormConfiguration';

import PromoCampaignFormInfo from '@/views/PromoView/PromoCampaignDialog/PromoCampaignFormInfo.vue';

export default {
  name: 'PromoCampaignDialog',
  components: {
    PromoCampaignFormInfo,
    SuccessPopupCheck,
    PromoCampaignFormTypes: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormTypes.vue'
      ),
    PromoCampaignFormCompany: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormCompany.vue'
      ),
    PromoCampaignFormRegistration: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormRegistration.vue'
      ),
    PromoCampaignFormPreSale: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormPreSale.vue'
      ),
    PromoCampaignFormAward: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormAward.vue'
      ),
    PromoCampaignFormCashback: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormCashback.vue'
      ),
    PromoCampaignFormAudience: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormAudience.vue'
      ),
    PromoCampaignFormAccrualFormat: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormAccrualFormat.vue'
      ),
    PromoCampaignFormAccrualCondition: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormAccrualCondition.vue'
      ),
    PromoCampaignFormDeadline: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormDeadline.vue'
      ),
    PromoCampaignFormCustomDeadline: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormCustomDeadline.vue'
      ),
    PromoCampaignFormCheckAll: (): any =>
      import(
        '@/views/PromoView/PromoCampaignDialog/PromoCampaignForm/PromoCampaignFormCheckAll.vue'
      )
  },
  props: {
    saving: {
      type: Boolean,
      default: false
    }
  },
  data: (): dataInterface => ({
    ETabKey,
    EFieldTypeKey,
    daySelectList,
    timeSelectList,
    gameTypesList,

    currentTabKey: ETabKey.CAMPAIGN_TYPE,

    formData: { ...defaultFormData },
    formsValid: { ...defaultFormsValidValue },
    enabledFields: { ...defaultEnabledFields },

    isShowCongratulations: false
  }),
  watch: {
    'dialog.campaignForm'(isShow: boolean): void {
      this.resetFormsData();

      if (isShow && this.selectedCampaign) {
        this.setFormData(this.selectedCampaign);
        this.currentTabKey = ETabKey.CHECK_ALL;
        return;
      }
      this.currentTabKey = ETabKey.CAMPAIGN_TYPE;
    }
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('Promo', ['selectedCampaign', 'dialog']),

    stepsDialog(): any {
      return [
        {
          id: ETabKey.CAMPAIGN_TYPE,
          component: 'promo-campaign-form-types'
        },
        {
          id: ETabKey.CAMPAIGN_NAME,
          component: 'promo-campaign-form-company'
        },
        {
          id: ETabKey.REGISTRATION_REWARD,
          component: 'promo-campaign-form-registration'
        },
        {
          id: ETabKey.PRE_SALE_COEFFICIENT,
          component: 'promo-campaign-form-pre-sale'
        },
        {
          id: ETabKey.AWARD_PROGRAM,
          component: 'promo-campaign-form-award'
        },
        {
          id: ETabKey.CASHBACK,
          component: 'promo-campaign-form-cashback'
        },
        {
          id: ETabKey.AUDIENCE,
          component: 'promo-campaign-form-audience'
        },
        {
          id: ETabKey.ACCRUAL_FORMAT,
          component: 'promo-campaign-form-accrual-format'
        },
        {
          id: ETabKey.ACCRUAL_CONDITION,
          component: 'promo-campaign-form-accrual-condition'
        },
        {
          id: ETabKey.DEADLINE,
          component: 'promo-campaign-form-deadline'
        },
        {
          id: ETabKey.CUSTOM_DEADLINE,
          component: 'promo-campaign-form-custom-deadline'
        },
        {
          id: ETabKey.CHECK_ALL,
          component: 'promo-campaign-form-check-all'
        }
      ];
    },

    campaignId(): number | null {
      return this.selectedCampaign?.id || null;
    },
    isCreateMode(): boolean {
      return this.campaignId === null;
    },
    isViewMode(): boolean {
      return (
        this.campaignId && ['ACTIVE'].includes(this.selectedCampaign?.status)
      );
    },
    isEditMode(): boolean {
      return this.campaignId && !this.isViewMode;
    },
    dialogHeaderText(): string {
      if (this.isViewMode) return 'Campaign Details';
      if (this.isEditMode) return 'Edit Campaign';

      return 'New marketing campaign';
    },
    currentTabIndex(): number {
      return this.tabKeysList.findIndex(
        (tabKey) => tabKey === this.currentTabKey
      );
    },
    tabKeysList(): Array<string> {
      return getTabKeysList(this.formData.campaignType);
    },
    tabsContent(): any {
      return getTabsContent(this.formData, this.enabledFields);
    },
    isShowNextButton(): boolean {
      const tabsLength = this.tabKeysList.length;
      const arrHideIndex = [tabsLength - 1];

      return !arrHideIndex.includes(this.currentTabIndex) && !this.isEditMode;
    },
    isShowPreviousButton(): boolean {
      const tabsLength = this.tabKeysList.length;
      const arrHideIndex = [0, tabsLength - 1];

      return !arrHideIndex.includes(this.currentTabIndex) && !this.isEditMode;
    },
    isShowOkButton(): boolean {
      const tabsLength = this.tabKeysList.length;

      return this.isEditMode && this.currentTabIndex !== tabsLength - 1;
    },
    isShowSaveButton(): boolean {
      const tabsLength = this.tabKeysList.length;

      return !this.isViewMode && this.currentTabIndex === tabsLength - 1;
    }
  },
  methods: {
    ...mapActions('Promo', ['showCampaignFormDialog']),

    updateFormData(field: { [key: string]: string }): void {
      this.formData = {
        ...this.formData,
        ...field
      };
    },

    updateEnabledFields(field: { [key: string]: string }): void {
      this.enabledFields = {
        ...this.enabledFields,
        ...field
      };
    },
    changeTabKey(key: string): any {
      this.currentTabKey = key;
    },
    changeCampaignType(campaignType: string): void {
      this.formData = {
        ...defaultFormData,
        campaignType
      };
      this.enabledFields = { ...defaultEnabledFields };
      this.resetValidation();
    },
    submitHandler(): void {
      const data = this.getDataToSend();

      this.$emit('submit', data, this.campaignId);
    },
    getDataToSend(): { [key: string]: string | number | boolean } {
      const payloadDataByCampaignType = getPayloadDataMap(
        this.formData,
        this.enabledFields
      );

      return payloadDataByCampaignType[this.formData.campaignType].reduce(
        (resultObj, dataItem) => {
          if (dataItem.dependency) {
            resultObj[dataItem.requestKey] = dataItem.value;
          }

          return resultObj;
        },
        {}
      );
    },
    setFormData(campaignData: PromoCampaignData): void {
      this.setCampaignType(campaignData);
      this.setEnabledFields(campaignData);

      const filteredRequestKeys = {
        ...mapRequestKey,
        ...(this.formData.campaignType === EPromoCampaignType.DEPOSIT
          ? {
              fixedAccrualLimitReward:
                this.enabledFields.accrualFormatType ===
                EFieldTypeKey.FIXED_DEPOSIT
                  ? mapRequestKey.fixedAccrualLimitReward
                  : undefined,
              percentageAccrualLimitReward:
                this.enabledFields.accrualFormatType ===
                EFieldTypeKey.PERCENTAGE_DEPOSIT
                  ? mapRequestKey.percentageAccrualLimitReward
                  : undefined
            }
          : {})
      };

      for (const [formDataKey, campaignDataKey] of Object.entries(
        filteredRequestKeys
      )) {
        if (campaignDataKey && campaignDataKey in campaignData) {
          this.formData[formDataKey] = keysToConvertToPercentage.includes(
            campaignDataKey
          )
            ? toPercents(campaignData[campaignDataKey])
            : campaignData[campaignDataKey];
        }
      }
    },
    setCampaignType(campaignData: PromoCampaignData): void {
      if (campaignData.signUpRule)
        this.formData.campaignType = EPromoCampaignType.REGISTRATION_REWARD;
      else if (campaignData.kycRule)
        this.formData.campaignType = EPromoCampaignType.KYC_REWARD;
      else if (
        campaignData.dailyCashbackReward ||
        campaignData.weeklyCashbackReward
      )
        this.formData.campaignType = EPromoCampaignType.CASHBACK;
      else if (campaignData.accrualCondition)
        this.formData.campaignType = EPromoCampaignType.DEPOSIT;
      else if (campaignData.preSaleCoefficient)
        this.formData.campaignType = EPromoCampaignType.TOKEN_PRE_SALE;
      else this.formData.campaignType = EPromoCampaignType.CUSTOM;
    },
    setEnabledFields(campaignData: PromoCampaignData): void {
      const campaignTypeFieldsConfig = getCampaignTypeFieldsConfig(
        campaignData,
        this.formData.campaignType
      );

      if (campaignTypeFieldsConfig) {
        this.enabledFields = {
          ...defaultEnabledFields,
          ...campaignTypeFieldsConfig
        };
      }
    },

    resetValidation(): void {
      for (const refName in this.$refs) {
        const childRef = this.$refs[refName][0]?.$refs?.form;

        if (childRef?.resetValidation) {
          childRef.resetValidation();
        }
      }
    },
    resetFormsData(): void {
      this.formData = { ...defaultFormData };
      this.enabledFields = { ...defaultEnabledFields };
    },
    nextTab(): void {
      const newTabKey = this.tabKeysList[this.currentTabIndex + 1];
      if (newTabKey) {
        this.currentTabKey = newTabKey;
      }
    },
    prevTab(): void {
      const newTabKey = this.tabKeysList[this.currentTabIndex - 1];
      if (newTabKey) {
        this.currentTabKey = newTabKey;
      }
    },
    moveToCheckAllTab(): void {
      this.currentTabKey = this.tabKeysList[this.tabKeysList.length - 1];
    },
    closeAction(): void {
      this.isShowCongratulations = false;
      this.showCampaignFormDialog({ status: false });
      this.$emit('close');
    },
    showCongratulations(): void {
      this.isShowCongratulations = true;
    }
  }
};
